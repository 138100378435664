import React from "react";
import CurrentLinkRequiresTeam from "../../app/game/in-game/current-link-requires-team/CurrentLinkRequiresTeam";
import ClueAdvance from "../../app/game/in-game/clue-advance/clue-advance";

const ClueAdvancePage = () => {
  return <CurrentLinkRequiresTeam>
    <ClueAdvance />
  </CurrentLinkRequiresTeam>;
};

export default ClueAdvancePage;
