import React, {useContext, useEffect, useState} from "react";
import GameContext from "../../../../context/game-context";
import {StringParam, useQueryParam} from "use-query-params";
import useCurrentClueLogic from "../../../../hooks/useCurrentClueLogic";
import {AppAlertsContext} from "../../../../context/app-alerts-context";
import appContent from "../../../../markdown/app-content";
import {AlertType} from "../../../../components/providers/app-alerts/AppAlerts";
import {navigate} from "gatsby";
import {LoadingSpinner, MarkdownPageLayout} from "../../../../components/components";
import ApplyCustomGameTheme from "../../shared/apply-custom-game-theme/ApplyCustomGameTheme";
import useClueAdvanceCheck, {USE_CLUE_ADVANCE_CHECK_RESULT} from "../../../../hooks/useClueAdvanceCheck";
import {CrossTabContext} from "../../../../context/cross-tab-context";
import CurrentLinkNoTeamFoundContent from "../current-link-requires-team/CurrentLinkNoTeamFoundContent";

const ClueAdvance = () => {
  const [hasCheckedClue, setHasCheckedClue] = useState(false);
  const {game, team, isLoading} = useContext(GameContext);
  const [code] = useQueryParam("code", StringParam);
  const {clue} = useCurrentClueLogic(game, team);
  const {popAlert, popError} = useContext(AppAlertsContext);
  const handleAdvanceQuestionCode = useClueAdvanceCheck();
  const {checkAdvance} = useContext(CrossTabContext);
  const [showContent, setShowContent] = useState(null);

  useEffect(async () => {
    if (isLoading || hasCheckedClue) {
      return;
    }

    /* If No Clue, user is not authenticated. Try in Another Tab, */
    if (!clue) {
      setHasCheckedClue(true);
      try {
        const response = await checkAdvance(code);
        setShowContent(
          <MarkdownPageLayout
            logo="lg"
            markdown={
              response === USE_CLUE_ADVANCE_CHECK_RESULT.CORRECT ?
                appContent.inGame.clueAdvance.correctAnswerCrossTabMarkdown :
                appContent.inGame.clueAdvance.wrongAnswerCrossTabMarkdown
            }
          />
        );
      } catch (e) {
        setShowContent(<CurrentLinkNoTeamFoundContent/>);
      }
      return; // Likely Handled By Cross Tab Communication
    }

    /* Normal Handling of Clue Advanced */
    if (code) {
      setHasCheckedClue(true);
      const result = await handleAdvanceQuestionCode(code);
      if (result === USE_CLUE_ADVANCE_CHECK_RESULT.CORRECT) {
        popAlert(appContent.inGame.clueAdvance.correctAnswerMessage, AlertType.SUCCESS);
      } else {
        popError(appContent.inGame.clueAdvance.wrongAnswerMessage, AlertType.WARNING);
      }
      await navigate("/game/in-game");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, hasCheckedClue, clue]);

  return <LoadingSpinner isLoading={isLoading || !showContent}>
    {showContent && <p>{showContent}</p>}
    <ApplyCustomGameTheme/>
  </LoadingSpinner>;
};

export default ClueAdvance;
